import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import SideBar from './SideBar'
import Layout from './Layout'
import {
  HomePage,
  ArticleFeed,
  BlogWrapper,
  BlogHeader,
  BlogTitle,
  Meta,
  MetaAuthor,
  MiniPost,
  StyledImage,
  BlogBody,
  Banner,
  HomeTitle,
  LinkButton,
} from '../styles'

const Articles = ({ posts }) => {
  const isCountries = () => {
    if (typeof window !== `undefined`) {
      return /countries/.test(window.decodeURI(window.document.URL))
    }
  }

  return (
    <Layout removeFooter={true} adsense={true}>
      <HomePage>
        <HomeTitle>
          <h2>Path.Money</h2>
        </HomeTitle>
        {posts.length ? <SideBar posts={posts.slice(0, 4)} /> : null}
        <ArticleFeed>
          {posts.map(({ node: post }) => {
            const {
              frontmatter: { title, date, author, authorPicture, banner, snippet},
              fields: { slug },
            } = post
            return (
              <BlogWrapper>
                <BlogHeader>
                  <BlogTitle>
                    <Link to={slug}>
                      <h2>{title}</h2>
                    </Link>
                  </BlogTitle>
                  <Meta>
                    <time>{date}</time>
                    <div>
                      <MetaAuthor href="#">
                        {author && authorPicture ? (
                          <Img fixed={authorPicture.childImageSharp.fixed} />
                        ) : null}
                        <span>{author}</span>
                      </MetaAuthor>
                    </div>
                  </Meta>
                </BlogHeader>
                <BlogBody>
                  <Banner>
                    {banner ? (
                      <Link to={slug}>
                        {banner && (
                          <StyledImage fluid={banner.childImageSharp.fluid} />
                        )}
                      </Link>
                    ) : null}
                  </Banner>
                  <p>{snippet}</p>
                  <LinkButton to={slug}>Continue Reading</LinkButton>
                </BlogBody>
              </BlogWrapper>
            )
          })}
        </ArticleFeed>
      </HomePage>
    </Layout>
  )
}

export default Articles
